export const QuestionType = {
  MELATONINA: 1,
  ED: 2,
  HAIR_LOSS: 3,
  EJ: 4,
  BE: 5,
};

export const QuestionTypeMap = new Map([
  [ QuestionType.MELATONINA, 'melatonina' ],
  [ QuestionType.ED, 'ed' ],
  [ QuestionType.HAIR_LOSS, 'quedadecabelo' ],
  [ QuestionType.EJ, 'ej' ],
  [ QuestionType.BE, 'be' ],
]);

export const QuestionTypeNames = [
  {
    id: QuestionType.MELATONINA,
    name: 'melatonina',
    color: '#8371B9',
  },
  {
    id: QuestionType.ED,
    name: 'ed',
    color: '#25505B',
  },
  {
    id: QuestionType.HAIR_LOSS,
    name: 'quedadecabelo',
    color: '#6DA7CB',
  },
  {
    id: QuestionType.EJ,
    name: 'ej',
    color: '#6DA7CB',
  },
  {
    id: QuestionType.BE,
    name: 'be',
    color: '#626262',
  },
];

export const GenderEnum = {
  MALE: 1,
  FEMALE: 2,
};

export const getQuestionTypeName = (type) => {
  return QuestionTypeNames.find((o) => o.id === type).name;
};

export const getQuestionTypeColor = (type) => {
  return QuestionTypeNames.find((o) => o.id === type).color;
};

export const QuestionAnswerType = {
  SINGLE_CHOICE: 1,
  MULTIPLE_CHOICE: 2,
  OPEN_TEXT: 3,
};

const questions = [
  // PAGE 1
  {
    id: 1,
    page: 1,
    slug: 'melatonina',
    title: 'Por qual motivo você quer usar melatonina?',
    type: QuestionType.MELATONINA,
    color: '#8371B9',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Insônia',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value:
          'Mudança de turno de trabalho (trabalhar durante a noite e dormir durante o dia)',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 3,
        value:
          'Viagens frequentes para outros países e dificuldade em se adaptar ao fuso horário',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 5,
        value: 'Outros',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 6,
        value: '',
        placeholder: 'Descreva aqui o motivo',
        type: QuestionAnswerType.OPEN_TEXT,
        selectionNeeded: [ 5 ],
        keep: true,
        keepParent: true,
      },
    ],
  },
  // PAGE 2
  {
    id: 2,
    page: 2,
    title: 'Você tem dificuldade de pegar no sono?',
    type: QuestionType.MELATONINA,
    color: '#8371B9',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 4,
        value:
          'Quanto tempo geralmente você demora para adormecer (em minutos)?',
        placeholder: 'Digite a sua resposta',
        type: QuestionAnswerType.OPEN_TEXT,
        selectionNeeded: [ 2 ],
        keep: true,
        keepParent: true,
      },
    ],
  },

  // PAGE 3
  {
    id: 3,
    page: 3,
    title: 'Você acorda quantas vezes durante a noite?',
    type: QuestionType.MELATONINA,
    color: '#8371B9',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Nenhuma',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: '1 a 2 vezes',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 3,
        value: '3 ou mais vezes',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 4,
        value:
          'Por favor, descreva qual motivo te faz acordar a noite (exemplo: sem nenhum motivo aparente, vontade de urinar, dores no corpo, ansiedade, preocupação)',
        placeholder: 'Digite a sua resposta aqui',
        type: QuestionAnswerType.OPEN_TEXT,
        selectionNeeded: [ 2, 3 ],
        keepParent: true,
        keep: true,
      },
    ],
  },

  // PAGE 4
  {
    id: 4,
    page: 4,
    title: 'Quantas vezes na semana você tem esse problema para dormir?',
    type: QuestionType.MELATONINA,
    color: '#8371B9',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Todos os dias',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Mais de 3 vezes na semana',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 3,
        value: 'Entre 1 e 3 vezes na semana',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 4,
        value: 'Apenas 1 vez ou nenhuma',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },

  // PAGE 5
  {
    id: 5,
    page: 5,
    title: 'Há quanto tempo você tem esse problema para dormir?',
    type: QuestionType.MELATONINA,
    color: '#8371B9',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Há menos de 1 semana',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Entre 1 e 3 semanas',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 3,
        value: 'Há 4 semanas',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 4,
        value: 'Há mais de 1 mês e menos de 3 meses',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 5,
        value: 'Há mais de 3 meses',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },

  // PAGE 6
  {
    id: 6,
    page: 6,
    title: 'Qual horário você normalmente dorme?',
    type: QuestionType.MELATONINA,
    color: '#8371B9',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: null,
        type: QuestionAnswerType.OPEN_TEXT,
        keep: true,
        keepParent: true,
      },
    ],
  },
  {
    id: 7,
    page: 6,
    title: 'Qual horário você normalmente acorda?',
    type: QuestionType.MELATONINA,
    color: '#8371B9',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 2,
        value: null,
        type: QuestionAnswerType.OPEN_TEXT,
        keep: true,
        keepParent: true,
      },
    ],
  },

  // PAGE 7
  {
    id: 8,
    page: 7,
    title: 'Você tem muita sonolência durante o dia?',
    type: QuestionType.MELATONINA,
    color: '#8371B9',
    nextButtonText: 'PRÓXIMO',
    inline: false,
    answers: [
      {
        id: 2,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 1,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },

  // PAGE 8
  {
    id: 9,
    page: 8,
    title: 'Você toma café ou algum produto com cafeina?',
    type: QuestionType.MELATONINA,
    color: '#8371B9',
    nextButtonText: 'PRÓXIMO',
    inline: false,
    answers: [
      {
        id: 2,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 1,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 3,
        value: 'Qual  produto? Qual quantidade/xícaras por dia?',
        placeholder: 'Digite a sua resposta aqui',
        type: QuestionAnswerType.OPEN_TEXT,
        selectionNeeded: [ 2 ],
        keep: true,
        keepParent: true,
      },
    ],
  },

  // PAGE 09
  {
    id: 11,
    page: 9,
    title: 'Você faz exercício físico à noite?',
    inline: false,
    type: QuestionType.MELATONINA,
    color: '#8371B9',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 2,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 1,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },

  // PAGE 10
  {
    id: 12,
    page: 10,
    title:
      'Você geralmente usa tablet, celular ou computador 1 hora antes de dormir?',
    type: QuestionType.MELATONINA,
    color: '#8371B9',
    nextButtonText: 'PRÓXIMO',
    inline: false,
    answers: [
      {
        id: 2,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 1,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },

  // PAGE 11
  {
    id: 13,
    page: 11,
    title: 'Você tem algum problema de saúde?',
    type: QuestionType.MELATONINA,
    color: '#8371B9',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Pressão alta',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
      },
      {
        id: 2,
        value: 'Pressão baixa',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
      },
      {
        id: 3,
        value: 'Diabetes',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
      },
      {
        id: 4,
        value: 'Doença cardíaca*',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
      },
      {
        id: 5,
        value: 'Problema na tireóide*',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
      },
      {
        id: 6,
        value: 'Outro',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
      },
      {
        id: 7,
        singleSelect: true,
        value: 'Não tenho problemas de saude',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
      },
    ],
  },
  {
    id: 131,
    page: 11,
    title: '',
    type: QuestionType.MELATONINA,
    color: '#8371B9',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: '',
        placeholder: '* Descreva o problema de saúde selecionado ',
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: [
          { id: 13, value: 'Doença cardíaca*' },
          { id: 13, value: 'Problema na tireóide*' },
          { id: 13, value: 'Outro' },
        ],
      },
    ],
  },

  // PAGE 12
  {
    id: 14,
    page: 12,
    title: 'Você utiliza alguma medicação?',
    type: QuestionType.MELATONINA,
    color: '#8371B9',
    nextButtonText: 'PRÓXIMO',
    inline: false,
    answers: [
      {
        id: 2,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 1,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 3,
        value: 'Qual o nome da medicação e a dosagem?',
        placeholder: 'Digite a sua resposta aqui',
        type: QuestionAnswerType.OPEN_TEXT,
        selectionNeeded: [ 2 ],
        keep: true,
        keepParent: true,
      },
    ],
  },

  // PAGE 13
  {
    id: 15,
    page: 13,
    title:
      'Você se sente ansiosa(o) ou com sintomas de depressão (tristeza intensa, sem vontade de fazer as atividades diárias, sonolência durante o dia, dificuldade de se concentrar)?',
    type: QuestionType.MELATONINA,
    color: '#8371B9',
    nextButtonText: 'PRÓXIMO',
    inline: false,
    answers: [
      {
        id: 2,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 1,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },

  // PAGE 14
  {
    id: 16,
    page: 14,
    title: 'Você trabalha operando máquinas ou dirigindo?',
    type: QuestionType.MELATONINA,
    color: '#8371B9',
    nextButtonText: 'PRÓXIMO',
    inline: false,
    answers: [
      {
        id: 2,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 1,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },

  // PAGE 15
  {
    id: 17,
    page: 15,
    title: 'Qual seu sexo biológico?',
    type: QuestionType.MELATONINA,
    color: '#8371B9',
    nextButtonText: 'PRÓXIMO',
    inline: false,
    answers: [
      {
        id: 2,
        value: 'Mulher',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 1,
        value: 'Homem',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 18,
    page: 15,
    title: 'Você está grávida?',
    type: QuestionType.MELATONINA,
    color: '#8371B9',
    nextButtonText: 'PRÓXIMO',
    inline: false,
    answers: [
      {
        id: 1,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: { id: 17, value: 'Mulher' },
      },
      {
        id: 2,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: { id: 17, value: 'Mulher' },
      },
    ],
  },
  {
    id: 19,
    page: 15,
    title: 'Você utiliza algum anticoncepcional? Qual?',
    type: QuestionType.MELATONINA,
    color: '#8371B9',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: '',
        placeholder: 'Digite a sua resposta aqui',
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: { id: 17, value: 'Mulher' },
        keep: true,
      },
    ],
  },
  {
    id: 20,
    page: 15,
    title: 'Você está amamentando?',
    type: QuestionType.MELATONINA,
    color: '#8371B9',
    nextButtonText: 'PRÓXIMO',
    inline: false,
    answers: [
      {
        id: 1,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: { id: 17, value: 'Mulher' },
      },
      {
        id: 2,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: { id: 17, value: 'Mulher' },
      },
    ],
  },
  {
    id: 21,
    page: 15,
    title: 'Qual a data da sua última menstruação?',
    type: QuestionType.MELATONINA,
    color: '#8371B9',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: '',
        placeholder: 'Digite a sua resposta aqui',
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: { id: 17, value: 'Mulher' },
        keep: true,
      },
    ],
  },
  {
    id: 40,
    page: 16,
    title: 'Identificação',
    type: QuestionType.MELATONINA,
    color: '#8371B9',
    nextButtonText: 'PRÓXIMO',
    answers: [],
  },

  // ED
  {
    id: 22,
    page: 1,
    title: 'Qual seu sexo biológico?',
    type: QuestionType.ED,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    inline: false,
    answers: [
      {
        id: 1,
        value: 'Mulher',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Homem',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 23,
    page: 2,
    title: 'Você está procurando uma solução para o que?',
    type: QuestionType.ED,
    slug: 'ed',
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Falta de libido (vontade de fazer sexo)',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Dificuldade em ter ou manter ereção',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 3,
        value: 'Ejaculação precoce',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 4,
        value: 'Melhorar a ereção',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 23,
    page: 3,
    title: 'Você tem filhos?',
    type: QuestionType.ED,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 2,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 1,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 24,
    page: 4,
    title: 'É constante sua disfunção erétil?',
    type: QuestionType.ED,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Sim, sempre ocorre',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Sim, a maioria das vezes ocorre',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 3,
        value: 'As vezes ocorre',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 4,
        value: 'Não, raramente ocorre',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 5,
        value: 'Nunca ocorreu',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 26,
    page: 5,
    title: 'Qual desses cenários melhor descreve seu problema?',
    type: QuestionType.ED,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Não consigo ficar ereto',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
      },
      {
        id: 2,
        value: 'Não consigo manter a ereção',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
      },
      {
        id: 3,
        value: 'Gostaria de ter uma performance melhor',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
      },
    ],
  },
  {
    id: 29,
    page: 6,
    title: 'O seu apetite sexual está menor do que no passado?',
    type: QuestionType.ED,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    inline: false,
    answers: [
      {
        id: 1,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 121,
    page: 7,
    title:
      'Por favor, confirme se você já teve algum efeito colateral no uso de TADALAFILA.',
    type: QuestionType.ED,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 123,
    page: 7,
    title:
      'Descreva abaixo os efeitos colaterais que teve durante o uso de TADALAFILA:',
    type: QuestionType.ED,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: '',
        placeholder: 'Digite sua resposta',
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: { id: 121, value: 'Sim' },
        keep: true,
        keepParent: true,
      },
    ],
  },
  {
    id: 124,
    page: 7,
    title:
      'Descreva abaixo os efeitos colaterais que teve durante o uso de TADALAFILA:',
    type: QuestionType.ED,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: '',
        placeholder: 'Digite sua resposta',
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: { id: 122, value: 'Sim' },
        keep: true,
        keepParent: true,
      },
    ],
  },
  {
    id: 125,
    page: 7,
    type: QuestionType.ED,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    haveTextBold: true,
    initialText:
      'Caso a indicação médica seja do uso do tadalafila/tadalaspray, você está ',
    textBold: 'CIENTE e CONFORTÁVEL ',
    finalText:
      'em seguir com a recomendação, sabendo que os efeitos colaterais relatados acima poderão ocorrer?',
    answers: [
      {
        id: 2,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: { id: 121, value: 'Sim' },
        attentionQuestion: true,
      },
      {
        id: 1,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: { id: 121, value: 'Sim' },
        attentionQuestion: true,
      },
    ],
    followConsultation:
      'Ao seguir você se declara ciente e confortável para os possíveis efeitos colaterais',
  },
  {
    id: 37,
    page: 8,
    title:
      'Por quanto tempo você o tomou (medicamento selecionado)? Qual a dosagem? Você gostaria de trocar ?',
    type: QuestionType.ED,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: '',
        placeholder: 'Descreva aqui',
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: [
          { id: 36, value: 'Ele não funcionou' },
          { id: 35, value: 'Sim' },
        ],
        keep: true,
        keepParent: true,
      },
    ],
  },
  {
    id: 38,
    page: 8,
    title: 'Quando você fez seu último check-up com o médico?',
    type: QuestionType.ED,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Fiz há menos de 1 ano',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Fiz há 1-2 anos atrás',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 3,
        value: 'Fiz há 2-3 anos atrás',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 4,
        value: 'Fiz há mais de 3 anos atrás',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 63,
    page: 10,
    title: 'Você toma algum dos medicamentos abaixo?',
    type: QuestionType.ED,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value:
          'Isordil, Isossorbida, Dilatrat, Dinitrato de Isossorbida, Mononitrato de isossorbida, imdur, monoket',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 62, value: 'Sim' },
      },
      {
        id: 2,
        value:
          'Suplementos de óxido nítrico, como L-citrulina, L-arginina e Pycnogenol',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 62, value: 'Sim' },
      },
      {
        id: 3,
        value: 'Outros medicamentos que utilizem nitratos',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 62, value: 'Sim' },
      },
      {
        id: 4,
        value: 'Riociguat (adempas)',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 62, value: 'Sim' },
      },
      {
        id: 5,
        value:
          'Bloqueador alpha-adrenérgico usado para tratar a hiperplasia benigna da próstata  -> tansulosina (Flomax), alfuzosina (Uroxatral), Silodosina (Rapaflo)',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 62, value: 'Sim' },
      },
      {
        id: 6,
        value:
          'Bloqueador alpha-adrenérgico usado para tratar a hiperplasia benigna da próstata  -> tansulosina (Flomax), alfuzosina (Uroxatral), Silodosina (Rapaflo)',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 62, value: 'Sim' },
      },
      {
        id: 7,
        value:
          'Mesilato de doxazosina (Cardura), Prazosina (minipress), Hytrin (cloridrato de terazosina)',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 62, value: 'Sim' },
      },
      {
        id: 8,
        value:
          'Nitroglicerina em spray, tablets, adesivo, sublingual (Nitrolingual, nitrostat, Nitro-dur, nitromist, nitro-bid, transerm-nitro, nitro-time, deponit, minitran, nitrek, nitrogard, nitroglycn, nitrol aintment, nitrong, nitro-par)',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 62, value: 'Sim' },
      },
      {
        id: 9,
        value: 'Nenhum desses se aplica',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 62, value: 'Sim' },
      },
    ],
  },
  {
    id: 120,
    page: 11,
    title:
      'Por favor, liste todos os medicamentos, vitaminas e suplementos que você toma, com as devidas dosagens e periodicidade. Favor incluir também tudo que foi ministrado/tomado nas ultimas 2 semanas',
    type: QuestionType.ED,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: '',
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: [
          {
            id: 63,
            value:
              'Bloqueador alpha-adrenérgico usado para tratar a hiperplasia benigna da próstata  -> tansulosina (Flomax), alfuzosina (Uroxatral), Silodosina (Rapaflo)',
          },
          {
            id: 63,
            value:
              'Nitroglicerina em spray, tablets, adesivo, sublingual (Nitrolingual, nitrostat, Nitro-dur, nitromist, nitro-bid, transerm-nitro, nitro-time, deponit, minitran, nitrek, nitrogard, nitroglycn, nitrol aintment, nitrong, nitro-par)',
          },
        ],
        placeholder: 'Digite sua resposta',
      },
    ],
  },
  {
    id: 64,
    page: 11,
    title: 'Qual medicamento você toma?',
    type: QuestionType.ED,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: '',
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: { id: 63, value: 'Nenhum desses se aplica' },
        placeholder: 'Digite sua resposta',
      },
    ],
  },
  {
    id: 51,
    page: 9,
    title: 'Você tem alguma alergia?',
    type: QuestionType.ED,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 2,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 1,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 128,
    page: 9,
    title: 'Já que sim, qual?',
    type: QuestionType.ED,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: '',
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: { id: 51, value: 'Sim' },
        placeholder: 'Digite sua resposta',
      },
    ],
  },
  {
    id: 56,
    page: 10,
    title: 'Você já teve algum sintoma de problema cardiovascular abaixo?',
    type: QuestionType.ED,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 6,
        value: 'Nenhum desses sintomas se aplica a mim',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        singleSelect: true,
      },
      {
        id: 5,
        value: 'Arritmia cardiaca',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
      },
      {
        id: 2,
        value: 'Dor no peito ao realizar atividade física ou sexual',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        alertCard: {
          title: 'Atenção:',
          description:
            'A confirmação de dor no peito está sujeita a necessidade de visita médica presencial para avalição de risco cardiológico.',
        },
      },
    ],
  },
  {
    id: 126,
    page: 11,
    title: 'Você toma medicamentos?',
    type: QuestionType.ED,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 2,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 1,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 127,
    page: 11,
    title:
      'Indique TODOS os medicamentos que utiliza com as respectivas dosagens',
    type: QuestionType.ED,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: { id: 126, value: 'Sim' },
      },
    ],
  },
  {
    id: 39,
    page: 12,
    type: QuestionType.ED,
    title: 'Identificação',
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [],
  },
  {
    id: 202,
    page: 11,
    title: 'Você utiliza algum medicamento a base de Nitrato?',
    type: QuestionType.ED,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: { id: 126, value: 'Sim' },
      },
      {
        id: 2,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: { id: 126, value: 'Sim' },
      },
    ],
  },
  {
    id: 203,
    page: 11,
    type: QuestionType.ED,
    title: 'Qual medicamento?',
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value:
          'Trinitrato de glicerilo/GTN/preparações de nitroglicerina (Nitromint®, Nitradisc®, Nitro-Dur®, Epinitril®, Plastranit®, Nitroderm-TTS®)',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 202, value: 'Sim' },
      },
      {
        id: 2,
        value: 'Dinitrato de isossorbida (Flindix®, Flindix Retard®)',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 202, value: 'Sim' },
      },
      {
        id: 3,
        value:
          'Mononitrato de isossorbida (Imdur, Monopront®, Ismoretard®, Monoket®)',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 202, value: 'Sim' },
      },
    ],
    message:
      'O Tadalaspray pode interagir com medicamentos a base de nitrato, assim, seu uso não é recomendado',
  },

  // BE
  {
    id: 22,
    page: 1,
    title: 'Você está procurando uma solução para o que?',
    type: QuestionType.BE,
    slug: 'ed',
    color: '#626262',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Falta de libido (vontade de fazer sexo)',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Dificuldade em ter ou manter ereção',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 3,
        value: 'Ejaculação precoce',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 4,
        value: 'Melhorar a ereção',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 23,
    page: 2,
    title: 'Você tem filhos?',
    type: QuestionType.BE,
    color: '#626262',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 2,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 1,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 24,
    page: 3,
    title: 'É constante sua disfunção erétil?',
    type: QuestionType.BE,
    color: '#626262',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Sim, sempre ocorre',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Sim, a maioria das vezes ocorre',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 3,
        value: 'As vezes ocorre',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 4,
        value: 'Não, raramente ocorre',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 5,
        value: 'Nunca ocorreu',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 26,
    page: 4,
    title: 'Qual desses cenários melhor descreve seu problema?',
    type: QuestionType.BE,
    color: '#626262',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Não consigo ficar ereto',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
      },
      {
        id: 2,
        value: 'Não consigo manter a ereção',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
      },
      {
        id: 3,
        value: 'Gostaria de ter uma performance melhor',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
      },
    ],
  },
  {
    id: 29,
    page: 5,
    title: 'O seu apetite sexual está menor do que no passado?',
    type: QuestionType.BE,
    color: '#626262',
    nextButtonText: 'PRÓXIMO',
    inline: false,
    answers: [
      {
        id: 1,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 121,
    page: 6,
    title:
      'Por favor, confirme se você já teve algum efeito colateral no uso de TADALAFILA.',
    type: QuestionType.BE,
    color: '#626262',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 123,
    page: 6,
    title:
      'Descreva abaixo os efeitos colaterais que teve durante o uso de TADALAFILA:',
    type: QuestionType.BE,
    color: '#626262',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: '',
        placeholder: 'Digite sua resposta',
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: { id: 121, value: 'Sim' },
        keep: true,
        keepParent: true,
      },
    ],
  },
  {
    id: 124,
    page: 6,
    title:
      'Descreva abaixo os efeitos colaterais que teve durante o uso de TADALAFILA:',
    type: QuestionType.BE,
    color: '#626262',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: '',
        placeholder: 'Digite sua resposta',
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: { id: 122, value: 'Sim' },
        keep: true,
        keepParent: true,
      },
    ],
  },
  {
    id: 125,
    page: 6,
    type: QuestionType.BE,
    color: '#626262',
    nextButtonText: 'PRÓXIMO',
    haveTextBold: true,
    initialText:
      'Caso a indicação médica seja do uso do tadalafila/tadalaspray, você está ',
    textBold: 'CIENTE e CONFORTÁVEL ',
    finalText:
      'em seguir com a recomendação, sabendo que os efeitos colaterais relatados acima poderão ocorrer?',
    answers: [
      {
        id: 2,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: { id: 121, value: 'Sim' },
        attentionQuestion: true,
      },
      {
        id: 1,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: { id: 121, value: 'Sim' },
        attentionQuestion: true,
      },
    ],
    followConsultation:
      'Ao seguir você se declara ciente e confortável para os possíveis efeitos colaterais',
  },
  {
    id: 37,
    page: 7,
    title:
      'Por quanto tempo você o tomou (medicamento selecionado)? Qual a dosagem? Você gostaria de trocar ?',
    type: QuestionType.BE,
    color: '#626262',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: '',
        placeholder: 'Descreva aqui',
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: [
          { id: 36, value: 'Ele não funcionou' },
          { id: 35, value: 'Sim' },
        ],
        keep: true,
        keepParent: true,
      },
    ],
  },
  {
    id: 38,
    page: 7,
    title: 'Quando você fez seu último check-up com o médico?',
    type: QuestionType.BE,
    color: '#626262',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Fiz há menos de 1 ano',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Fiz há 1-2 anos atrás',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 3,
        value: 'Fiz há 2-3 anos atrás',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 4,
        value: 'Fiz há mais de 3 anos atrás',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 63,
    page: 9,
    title: 'Você toma algum dos medicamentos abaixo?',
    type: QuestionType.BE,
    color: '#626262',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value:
          'Isordil, Isossorbida, Dilatrat, Dinitrato de Isossorbida, Mononitrato de isossorbida, imdur, monoket',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 62, value: 'Sim' },
      },
      {
        id: 2,
        value:
          'Suplementos de óxido nítrico, como L-citrulina, L-arginina e Pycnogenol',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 62, value: 'Sim' },
      },
      {
        id: 3,
        value: 'Outros medicamentos que utilizem nitratos',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 62, value: 'Sim' },
      },
      {
        id: 4,
        value: 'Riociguat (adempas)',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 62, value: 'Sim' },
      },
      {
        id: 5,
        value:
          'Bloqueador alpha-adrenérgico usado para tratar a hiperplasia benigna da próstata  -> tansulosina (Flomax), alfuzosina (Uroxatral), Silodosina (Rapaflo)',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 62, value: 'Sim' },
      },
      {
        id: 6,
        value:
          'Bloqueador alpha-adrenérgico usado para tratar a hiperplasia benigna da próstata  -> tansulosina (Flomax), alfuzosina (Uroxatral), Silodosina (Rapaflo)',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 62, value: 'Sim' },
      },
      {
        id: 7,
        value:
          'Mesilato de doxazosina (Cardura), Prazosina (minipress), Hytrin (cloridrato de terazosina)',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 62, value: 'Sim' },
      },
      {
        id: 8,
        value:
          'Nitroglicerina em spray, tablets, adesivo, sublingual (Nitrolingual, nitrostat, Nitro-dur, nitromist, nitro-bid, transerm-nitro, nitro-time, deponit, minitran, nitrek, nitrogard, nitroglycn, nitrol aintment, nitrong, nitro-par)',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 62, value: 'Sim' },
      },
      {
        id: 9,
        value: 'Nenhum desses se aplica',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 62, value: 'Sim' },
      },
    ],
  },
  {
    id: 120,
    page: 10,
    title:
      'Por favor, liste todos os medicamentos, vitaminas e suplementos que você toma, com as devidas dosagens e periodicidade. Favor incluir também tudo que foi ministrado/tomado nas ultimas 2 semanas',
    type: QuestionType.BE,
    color: '#626262',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: '',
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: [
          {
            id: 63,
            value:
              'Bloqueador alpha-adrenérgico usado para tratar a hiperplasia benigna da próstata  -> tansulosina (Flomax), alfuzosina (Uroxatral), Silodosina (Rapaflo)',
          },
          {
            id: 63,
            value:
              'Nitroglicerina em spray, tablets, adesivo, sublingual (Nitrolingual, nitrostat, Nitro-dur, nitromist, nitro-bid, transerm-nitro, nitro-time, deponit, minitran, nitrek, nitrogard, nitroglycn, nitrol aintment, nitrong, nitro-par)',
          },
        ],
        placeholder: 'Digite sua resposta',
      },
    ],
  },
  {
    id: 64,
    page: 10,
    title: 'Qual medicamento você toma?',
    type: QuestionType.BE,
    color: '#626262',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: '',
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: { id: 63, value: 'Nenhum desses se aplica' },
        placeholder: 'Digite sua resposta',
      },
    ],
  },
  {
    id: 51,
    page: 8,
    title: 'Você tem alguma alergia?',
    type: QuestionType.BE,
    color: '#626262',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 2,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 1,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 128,
    page: 8,
    title: 'Já que sim, qual?',
    type: QuestionType.BE,
    color: '#626262',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: '',
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: { id: 51, value: 'Sim' },
        placeholder: 'Digite sua resposta',
      },
    ],
  },
  {
    id: 56,
    page: 9,
    title: 'Você já teve algum sintoma de problema cardiovascular abaixo?',
    type: QuestionType.BE,
    color: '#626262',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 6,
        value: 'Nenhum desses sintomas se aplica a mim',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        singleSelect: true,
      },
      {
        id: 5,
        value: 'Arritmia cardiaca',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
      },
      {
        id: 2,
        value: 'Dor no peito ao realizar atividade física ou sexual',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        alertCard: {
          title: 'Atenção:',
          description:
            'A confirmação de dor no peito está sujeita a necessidade de visita médica presencial para avalição de risco cardiológico.',
        },
      },
    ],
  },
  {
    id: 126,
    page: 10,
    title: 'Você toma medicamentos?',
    type: QuestionType.BE,
    color: '#626262',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 2,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 1,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 127,
    page: 10,
    title:
      'Indique TODOS os medicamentos que utiliza com as respectivas dosagens',
    type: QuestionType.BE,
    color: '#626262',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: { id: 126, value: 'Sim' },
      },
    ],
  },
  {
    id: 39,
    page: 11,
    type: QuestionType.BE,
    title: 'Identificação',
    color: '#626262',
    nextButtonText: 'PRÓXIMO',
    answers: [],
  },
  {
    id: 202,
    page: 10,
    title: 'Você utiliza algum medicamento a base de Nitrato?',
    type: QuestionType.BE,
    color: '#626262',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: { id: 126, value: 'Sim' },
      },
      {
        id: 2,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: { id: 126, value: 'Sim' },
      },
    ],
  },
  {
    id: 203,
    page: 10,
    type: QuestionType.BE,
    title: 'Qual medicamento?',
    color: '#626262',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value:
          'Trinitrato de glicerilo/GTN/preparações de nitroglicerina (Nitromint®, Nitradisc®, Nitro-Dur®, Epinitril®, Plastranit®, Nitroderm-TTS®)',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 202, value: 'Sim' },
      },
      {
        id: 2,
        value: 'Dinitrato de isossorbida (Flindix®, Flindix Retard®)',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 202, value: 'Sim' },
      },
      {
        id: 3,
        value:
          'Mononitrato de isossorbida (Imdur, Monopront®, Ismoretard®, Monoket®)',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 202, value: 'Sim' },
      },
    ],
    message:
      'O Tadalaspray pode interagir com medicamentos a base de nitrato, assim, seu uso não é recomendado',
  },

  // EJ
  {
    id: 223,
    page: 1,
    title: 'Qual seu sexo biológico?',
    type: QuestionType.EJ,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    inline: false,
    answers: [
      {
        id: 1,
        value: 'Mulher',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Homem',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 22,
    page: 2,
    title: 'Você está procurando uma solução para o que?',
    type: QuestionType.EJ,
    slug: 'ed',
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Ejaculação Precoce',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Melhorar a Ereção',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 3,
        value: 'Ambos',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 23,
    page: 3,
    title: 'Você tem filhos?',
    type: QuestionType.EJ,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 2,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 1,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 24,
    page: 4,
    title: 'É constante sua ejaculação precoce?',
    type: QuestionType.EJ,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Sim, sempre ocorre',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Sim, a maioria das vezes ocorre',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 3,
        value: 'As vezes ocorre',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 4,
        value: 'Não, é raro ocorrer',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 26,
    page: 5,
    title: 'Qual desses cenários melhor descreve seu problema?',
    type: QuestionType.EJ,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Ejaculo antes de penetrar',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Ejaculo em menos de 2 minutos penetrando',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 3,
        value: 'Ejaculo entre 4 e 2 minutos penetrando',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 4,
        value: 'Ejaculo acima de 5 min, mas gostaria de demorar mais',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 29,
    page: 6,
    title: 'Quando você se masturba você também ejacula precocemente?',
    type: QuestionType.EJ,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    inline: false,
    answers: [
      {
        id: 1,
        value: 'Sim, é igual penetrando ou me masturbando',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Demoro mais do que penetrando, mas ainda é rápido',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 3,
        value: 'Não, demoro bem mais tempo se comparado ao sexo',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 132,
    page: 7,
    title: 'Você já utilizou paroxetina alguma vez?',
    type: QuestionType.EJ,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 534,
    page: 7,
    title: 'Descreva como foi o tratamento e se foi satisfatório',
    type: QuestionType.EJ,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: '',
        placeholder: 'Digite sua resposta',
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: { id: 132, value: 'Sim' },
        keep: true,
        keepParent: true,
      },
    ],
  },
  {
    id: 3125,
    page: 8,
    title: 'Você já utilizou dapoxetina alguma vez?',
    type: QuestionType.EJ,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 512,
    page: 8,
    title:
      'Por favor, confirme se você já teve algum efeito colateral no uso de DAPOXETINA',
    type: QuestionType.EJ,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: { id: 3125, value: 'Sim' },
      },
      {
        id: 2,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: { id: 3125, value: 'Sim' },
      },
    ],
  },
  {
    id: 5123,
    page: 8,
    title:
      'Descreva abaixo os efeitos colaterais que teve durante o uso de DAPOXETINA',
    type: QuestionType.EJ,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: '',
        placeholder: 'Digite sua resposta',
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: { id: 512, value: 'Sim' },
        keep: true,
        keepParent: true,
      },
    ],
  },
  {
    id: 5312,
    page: 8,
    title:
      'Caso a indicação médica seja do uso do dapoxetina, você está CIENTE e CONFORTÁVEL em seguir com a recomendação, sabendo que os efeitos colaterais relatados acima poderão ocorrer?',
    type: QuestionType.EJ,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: { id: 512, value: 'Sim' },
        attentionQuestion: true,
      },
      {
        id: 2,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: { id: 512, value: 'Sim' },
        attentionQuestion: true,
      },
    ],
  },
  {
    id: 5234,
    page: 9,
    title: 'Quando você fez seu último check-up com o médico?',
    type: QuestionType.EJ,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Fiz há menos de 1 ano',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Fiz há 1-2 anos atrás',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 3,
        value: 'Fiz há 2-3 anos atrás',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 4,
        value: 'Fiz há mais de 3 anos atrás',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 412,
    page: 10,
    title: 'Você tem alguma alergia?',
    type: QuestionType.EJ,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 2,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 1,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 652,
    page: 10,
    title: 'Descreva ao que você tem alergia medicamentosa',
    type: QuestionType.EJ,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: '',
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: { id: 412, value: 'Sim' },
        placeholder: 'Digite sua resposta',
      },
    ],
  },
  {
    id: 612,
    page: 11,
    title: 'Você já teve algum sintoma de problema cardiovascular abaixo?',
    type: QuestionType.EJ,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Nenhum desses sintomas se aplica a mim',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        singleSelect: true,
      },
      {
        id: 2,
        value: 'Arritmia cardiaca',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
      },
      {
        id: 3,
        value: 'Dor no peito ao realizar atividade física',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        alertCard: {
          title: 'Atenção:',
          description:
            'A confirmação de dor no peito está sujeita a necessidade de visita médica presencial para avalição de risco cardiológico.',
        },
      },
    ],
  },
  {
    id: 613,
    page: 12,
    title: 'Você toma algum medicamento?',
    type: QuestionType.EJ,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 2,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 1,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 652,
    page: 12,
    title: 'Descreva TODOS e a doença que possuí para toma-lo.',
    type: QuestionType.EJ,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: '',
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: { id: 613, value: 'Sim' },
        placeholder: 'Digite sua resposta',
      },
    ],
  },
  {
    id: 618,
    page: 13,
    title: 'Voce já fez algum tratamento para ejaculação precoce?',
    type: QuestionType.EJ,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 2,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 1,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 652,
    page: 13,
    title: 'Descreva o tratamento e se foi satisfatório',
    type: QuestionType.EJ,
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: '',
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: { id: 618, value: 'Sim' },
        placeholder: 'Digite sua resposta',
      },
    ],
  },
  {
    id: 641,
    page: 14,
    type: QuestionType.EJ,
    title: 'Identificação',
    color: '#4C9C9C',
    nextButtonText: 'PRÓXIMO',
    answers: [],
  },

  // PAGE 1
  {
    id: 71,
    page: 1,
    slug: 'quedadecabelo',
    title: 'Qual seu sexo biológico?',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Mulher',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Homem',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 72,
    page: 2,
    slug: 'quedadecabelo',
    title: 'Como você descreve seu cabelo?',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Entradas visíveis',
        icon: '/assets/img/hair-loss/entrada.png',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Afinamento do cabelo / coroa vísivel',
        icon: '/assets/img/hair-loss/coroa.png',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 3,
        value: 'Queda geral e afinamento do cabelo',
        icon: '/assets/img/hair-loss/queda-geral.png',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 4,
        value: 'Nada aparente',
        icon: '/assets/img/hair-loss/cabeludo.png',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 73,
    page: 3,
    slug: 'quedadecabelo',
    title: 'Há quanto tempo você percebeu este problema?',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Menos de um mês',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Entre 1 e 6 meses',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 3,
        value: 'Mais de 6 meses',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 74,
    page: 3,
    slug: 'quedadecabelo',
    title: 'Sua queda de cabelo tem sido gradual?',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Não, todos os meus fios caíram em poucos dias',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 75,
    page: 4,
    slug: 'quedadecabelo',
    title: 'Quais destes sintomas de perda de cabelo você percebeu?',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Afinamento dos fios',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
      },
      {
        id: 2,
        value: 'Caspa',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
      },
      {
        id: 3,
        value: 'Queda de cabelo na região frontal',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
      },
      {
        id: 4,
        value: 'Queda de cabelo por todo couro cabeludo',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
      },
      {
        id: 5,
        value: 'Queda de cabelo e pelo no corpo inteiro',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
      },
      {
        id: 6,
        value: 'Dor, coceira ou vermelhidão no couro cabeluo',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
      },
      {
        id: 7,
        value: 'Outro',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
      },
    ],
  },
  {
    id: 76,
    page: 5,
    slug: 'quedadecabelo',
    title:
      'Você já usou algum medicamento para queda de cabelo (loção, creme, comprimido)?',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Sim, minoxidil',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 3,
        value: 'Sim, outro medicamento',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 4,
        value: 'Sim, minoxidil e outro medicamento',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 77,
    page: 5,
    slug: 'quedadecabelo',
    title: 'Qual tipo de minoxidil você utilizava?',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Em solução',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: { id: 76, value: 'Sim, minoxidil' },
      },
      {
        id: 2,
        value: 'Em espuma (foam)',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: { id: 76, value: 'Sim, minoxidil' },
      },
      {
        id: 3,
        value: 'Em comprimido',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: { id: 76, value: 'Sim, minoxidil' },
      },
    ],
  },
  {
    id: 78,
    page: 5,
    slug: 'quedadecabelo',
    title:
      'Falando apenas do Minoxidil: por quanto tempo você utilizou o minoxidil?',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Menos de 1 mês',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: { id: 76, value: 'Sim, minoxidil' },
      },
      {
        id: 2,
        value: 'Entre 3-6 meses',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: { id: 76, value: 'Sim, minoxidil' },
      },
      {
        id: 3,
        value: 'Entre 6-12 meses',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: { id: 76, value: 'Sim, minoxidil' },
      },
      {
        id: 4,
        value: 'Acima de 12 meses',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: { id: 76, value: 'Sim, minoxidil' },
      },
    ],
  },
  {
    id: 79,
    page: 5,
    slug: 'quedadecabelo',
    title: 'Você sentiu alguma melhora?',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Sim, muita melhora',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: { id: 76, value: 'Sim, minoxidil' },
      },
      {
        id: 2,
        value: 'Sim, uma leve melhora',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: { id: 76, value: 'Sim, minoxidil' },
      },
      {
        id: 3,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: { id: 76, value: 'Sim, minoxidil' },
      },
    ],
  },
  {
    id: 80,
    page: 5,
    slug: 'quedadecabelo',
    title: 'Você ainda está usando Minoxidil?',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: { id: 76, value: 'Sim, minoxidil' },
      },
      {
        id: 2,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: { id: 76, value: 'Sim, minoxidil' },
      },
    ],
  },
  {
    id: 81,
    page: 5,
    slug: 'quedadecabelo',
    title: 'Qual medicamento você utilizou? Por quanto tempo?',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: '',
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: { id: 76, value: 'Sim, outro medicamento' },
      },
    ],
  },
  {
    id: 82,
    page: 5,
    slug: 'quedadecabelo',
    title: 'Você teve algum período de melhora? Ainda está em uso?',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: '',
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: { id: 76, value: 'Sim, outro medicamento' },
      },
    ],
  },
  {
    id: 83,
    page: 5,
    slug: 'quedadecabelo',
    title: 'Qual medicamento você utilizou? Por quanto tempo?',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: '',
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: {
          id: 76,
          value: 'Sim, minoxidil e outro medicamento',
        },
      },
    ],
  },
  {
    id: 84,
    page: 5,
    slug: 'quedadecabelo',
    title: 'Você teve algum período de melhora? Ainda está em uso?',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: '',
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: {
          id: 76,
          value: 'Sim, minoxidil e outro medicamento',
        },
      },
    ],
  },
  {
    id: 85,
    page: 5,
    slug: 'quedadecabelo',
    title:
      'Falando apenas do Minoxidil: por quanto tempo você utilizou o minoxidil?',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Menos de 1 mês',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: {
          id: 76,
          value: 'Sim, minoxidil e outro medicamento',
        },
      },
      {
        id: 2,
        value: 'Entre 3-6 meses',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: {
          id: 76,
          value: 'Sim, minoxidil e outro medicamento',
        },
      },
      {
        id: 3,
        value: 'Entre 6-12 meses',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: {
          id: 76,
          value: 'Sim, minoxidil e outro medicamento',
        },
      },
      {
        id: 4,
        value: 'Acima de 12 meses',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: {
          id: 76,
          value: 'Sim, minoxidil e outro medicamento',
        },
      },
    ],
  },
  {
    id: 86,
    page: 5,
    slug: 'quedadecabelo',
    title: 'Você sentiu alguma melhora?',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Sim, muita melhora',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: {
          id: 76,
          value: 'Sim, minoxidil e outro medicamento',
        },
      },
      {
        id: 2,
        value: 'Sim, uma leve melhora',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: {
          id: 76,
          value: 'Sim, minoxidil e outro medicamento',
        },
      },
      {
        id: 3,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: {
          id: 76,
          value: 'Sim, minoxidil e outro medicamento',
        },
      },
    ],
  },
  {
    id: 87,
    page: 5,
    slug: 'quedadecabelo',
    title: 'Você ainda está usando Minoxidil?',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: {
          id: 76,
          value: 'Sim, minoxidil e outro medicamento',
        },
      },
      {
        id: 2,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
        questionSelectionNeeded: {
          id: 76,
          value: 'Sim, minoxidil e outro medicamento',
        },
      },
    ],
  },
  {
    id: 88,
    page: 6,
    slug: 'quedadecabelo',
    title: 'Outra pessoas da sua família tem queda de cabelo?',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 88,
    page: 7,
    slug: 'quedadecabelo',
    title: 'Você tem algum problema de saúde na pele ou no cabelo?',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 89,
    page: 7,
    slug: 'quedadecabelo',
    title: 'Você fez algum tratamento? Se sim, com qual medicamento?',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: '',
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: { id: 88, value: 'Sim' },
      },
    ],
  },
  {
    id: 90,
    page: 7,
    slug: 'quedadecabelo',
    title: 'Você ainda utiliza o medicamento prescrito?',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: '',
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: { id: 88, value: 'Sim' },
      },
    ],
  },
  {
    id: 101,
    page: 8,
    slug: 'quedadecabelo',
    title: 'Você já foi diagnosticado com algum problema de saúde?',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 102,
    page: 8,
    slug: 'quedadecabelo',
    title: 'Se sim, marque qual ou quais:',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Diabetes',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 101, value: 'Sim' },
      },
      {
        id: 2,
        value: 'Pressão alta ou baixa',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 101, value: 'Sim' },
      },
      {
        id: 3,
        value: 'Câncer',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 101, value: 'Sim' },
      },
      {
        id: 4,
        value: 'Câncer',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 101, value: 'Sim' },
      },
      {
        id: 5,
        value: 'Doenças no fígado',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 101, value: 'Sim' },
      },
      {
        id: 6,
        value: 'Lúpus',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 101, value: 'Sim' },
      },
      {
        id: 7,
        value: 'HIV',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 101, value: 'Sim' },
      },
      {
        id: 8,
        value: 'Doenças no sistema imune',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 101, value: 'Sim' },
      },
      {
        id: 9,
        value: 'Doenças no rins',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 101, value: 'Sim' },
      },
      {
        id: 10,
        value: 'Doenças na tireóide',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 101, value: 'Sim' },
      },
      {
        id: 11,
        value: 'Depressão ou ansiedade',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 101, value: 'Sim' },
      },
      {
        id: 12,
        value: 'Esquizofrenia',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 101, value: 'Sim' },
      },
      {
        id: 13,
        value: 'Osteoporose ou osteopenia (doença nos ossos)',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 101, value: 'Sim' },
      },
      {
        id: 14,
        value: 'Nenhuma das anteriores',
        type: QuestionAnswerType.MULTIPLE_CHOICE,
        questionSelectionNeeded: { id: 101, value: 'Sim' },
      },
    ],
  },
  {
    id: 103,
    page: 8,
    slug: 'quedadecabelo',
    title: 'Você faz acompanhamento com algum médico? Como vai o tratamento?',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    placeholder: 'Digite sua resposta aqui',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: '',
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: { id: 101, value: 'Sim' },
      },
    ],
  },
  {
    id: 92,
    page: 9,
    slug: 'quedadecabelo',
    title: 'Você já foi submetido a alguma cirurgia ou internação?',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 93,
    page: 9,
    slug: 'quedadecabelo',
    title:
      'Por qual motivo você teve que ser submetido a uma cirurgia ou internação? Quando isso ocorreu?',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: '',
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: { id: 92, value: 'Sim' },
      },
    ],
  },
  {
    id: 94,
    page: 10,
    slug: 'quedadecabelo',
    title: 'Você usa alguma medicação, suplemento ou vitamina?',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 95,
    page: 10,
    slug: 'quedadecabelo',
    title: 'Por favor, especifique o que toma:',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: '',
        type: QuestionAnswerType.OPEN_TEXT,
        questionSelectionNeeded: { id: 94, value: 'Sim' },
      },
    ],
  },
  {
    id: 96,
    page: 11,
    slug: 'quedadecabelo',
    title: 'Você tem alergia a Minoxidil?',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 3,
        value: 'Não sei, nunca usei',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 97,
    page: 12,
    slug: 'quedadecabelo',
    title: 'Você tem alergia a Finasterida?',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    gender: GenderEnum.MALE,
    nextButtonText: 'PRÓXIMO',
    answers: [
      {
        id: 1,
        value: 'Não',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 2,
        value: 'Sim',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
      {
        id: 3,
        value: 'Não sei, nunca usei',
        type: QuestionAnswerType.SINGLE_CHOICE,
      },
    ],
  },
  {
    id: 100,
    page: 13,
    title: 'Identificação',
    type: QuestionType.HAIR_LOSS,
    color: '#6DA7CB',
    nextButtonText: 'PRÓXIMO',
    answers: [],
  },
];

export const getPages = (type) => {
  return [
    ...new Set(questions.filter((o) => o.type === type).map((o) => o.page)),
  ];
};

export const getQuestionsByPage = (page, type) => {
  return questions.filter((o) => o.page === page && o.type === type);
};

export const getQuestionsByType = (type) => {
  return questions.filter((o) => o.type === type);
};

export const getQuestionBySlug = (slug) => {
  return questions.find((o) => o.slug === slug);
};

export const getProgressAmount = (page, type) => {
  const pages = getQuestionsByType(type);
  const amount = [ ...new Set(pages.map((o) => o.page)) ].length;

  return (page / amount) * 100;
};

export const getResponsesByPage = (page, type, quizUserResponse) => {
  let questions = getQuestionsByPage(page, type);

  const filteredQuizUserResponse =
    (quizUserResponse &&
      quizUserResponse.filter((o) => o.page === page && o.type === type)) ||
    [];

  if (filteredQuizUserResponse && filteredQuizUserResponse.length > 0) {
    questions = filteredQuizUserResponse;
  }

  return questions;
};

export const getThemeColor = (slug) => {
  const question = getQuestionBySlug(slug);

  if (question) {
    return question.color;
  }

  return '#3B5C7A';
};

export const getPageColor = (page, type) => {
  const questions = getQuestionsByPage(page, type);

  if (questions && questions.length > 0) {
    return questions[ 0 ].color;
  }

  return '#3B5C7A';
};

export const getNextPageByGender = (page, type, gender) => {
  const filteredQuestions = questions.filter(
    (o) =>
      o.page > page && o.type === type && (!o.gender || o.gender === gender),
  );
  return filteredQuestions[ 0 ].page;
};

export const getLastPage = (type) => {
  const filteredQuestions = questions.filter((o) => o.type === type);
  return Math.max.apply(
    Math,
    filteredQuestions.map((o) => o.page),
  );
};
